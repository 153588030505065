// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contatti-examples-js": () => import("./../../../src/pages/contatti/examples.js" /* webpackChunkName: "component---src-pages-contatti-examples-js" */),
  "component---src-pages-contatti-file-upload-js": () => import("./../../../src/pages/contatti/file-upload.js" /* webpackChunkName: "component---src-pages-contatti-file-upload-js" */),
  "component---src-pages-contatti-index-js": () => import("./../../../src/pages/contatti/index.js" /* webpackChunkName: "component---src-pages-contatti-index-js" */),
  "component---src-pages-contatti-thanks-js": () => import("./../../../src/pages/contatti/thanks.js" /* webpackChunkName: "component---src-pages-contatti-thanks-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-master-page-js": () => import("./../../../src/templates/master-page.js" /* webpackChunkName: "component---src-templates-master-page-js" */),
  "component---src-templates-partners-page-js": () => import("./../../../src/templates/partners-page.js" /* webpackChunkName: "component---src-templates-partners-page-js" */),
  "component---src-templates-program-page-js": () => import("./../../../src/templates/program-page.js" /* webpackChunkName: "component---src-templates-program-page-js" */),
  "component---src-templates-projects-page-js": () => import("./../../../src/templates/projects-page.js" /* webpackChunkName: "component---src-templates-projects-page-js" */),
  "component---src-templates-students-page-js": () => import("./../../../src/templates/students-page.js" /* webpackChunkName: "component---src-templates-students-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-teachers-page-js": () => import("./../../../src/templates/teachers-page.js" /* webpackChunkName: "component---src-templates-teachers-page-js" */)
}

